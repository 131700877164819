:root {
    --black: #222022;
    --white: #FFF9EC;
}

@font-face {
    font-family: 'Garet';
    src: url('../public/fonts/Garet-Book.ttf');
    font-weight: normal;
}

@font-face {
    font-family: 'Garet';
    src: url('../public/fonts/Garet-Heavy.ttf');
    font-weight: bold;
}

@font-face {
    font-family: 'Inria';
    src: url('../public/fonts/InriaSerif-Regular.ttf');
}

@font-face {
    font-family: 'Playfair';
    src: url('../public/fonts/PlayfairDisplay-VariableFont_wght.ttf');
}

@font-face {
    font-family: 'Arimo';
    src: url('../public/fonts/Arimo-VariableFont_wght.ttf');
}

@font-face {
    font-family: 'DMSans';
    src: url('../public/fonts/DMSans-VariableFont_opsz,wght.ttf');
}

html, body {
    font-family: 'DMSans', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    margin: 0;
    padding: 0;
}

.align-end {
    display: flex;
    justify-content: flex-end;
}

.debug {
    border: 1px solid red;
}

.col {
    display: flex;
    flex-direction: column;
}
