.spacer {
    flex-grow: 1;
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 3rem;
    background-color: var(--black);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-height: 10vh;

    font-family: Arimo, sans-serif;
    font-weight: bold;
}

.logo-navbar {
    max-height: 10vh;
}

.link-navbar {
    color: white;
    text-decoration: none;
    font-size: 1rem;
    margin: 0 1rem;
}
