.hero {
    background-color: var(--white);
    display: flex;
    height: 85vh;
    padding-left: 3rem;
}

@media (max-width: 768px) {
    .hero {
        flex-direction: column;
        height: 100vh;
        padding: 0;
    }
}

.hero-title {
    font-family: 'Playfair', serif;
    font-size: 4.1rem;
    font-weight: bold;
    line-height: 1.1;
    margin-bottom: 1rem;
}

.hero-subtitle {
    font-family: 'Inria', serif;
    font-size: 1.5rem;
    font-weight: normal;
}

.hero-text-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    flex: 1;
}

.hero-decoration-column {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
    padding: 2rem;
    flex: 1;

    background-image: linear-gradient(to bottom, rgba(255,255,255,0.85) 100%, rgba(255,255,255,0.85) 100%), url("../../../public/images/empower.webp");
}

@media (max-width: 768px) {
    .hero-decoration-column {
        align-items: center;
    }
}

.hero-button {
    font-family: Playfair, serif;
    background-color: var(--white);
    border: 1px solid var(--black);

    font-size: 1rem;
    font-weight: bold;
    padding: 1rem 2rem;
    width: 12rem;
    margin-top: 2rem;

    transition: background-color 0.3s, color 0.3s;
}

.hero-button:hover {
    background-color: var(--black);
    color: var(--white);
    cursor: pointer;
}

.social-media-icon > svg {
    height: 3rem;
    width: 3rem;

    padding: 0.3rem;

    border: 2px solid var(--black);
    border-radius: 0.2rem;
    transition: border-radius 0.1s;
}

.social-media-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    gap: 0.5rem;
}

.social-media-icon > svg:hover {
    cursor: pointer;
    border-radius: 1rem;
}