.post-creation {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.post-creation-title {
    font-family: 'Playfair', serif;
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}